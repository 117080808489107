<template>
  <NavBar :title="'核销记录'"></NavBar>
  <div class="content">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        class="items"
        @load="queryList"
      >
        <div class="item" v-for="item in list" :key="item.id">
          <img
            class="avatar"
            src="https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220613_104414_62a6a47e42202.png"
            alt="成员"
          />
          <div class="info">
            <p>{{ item.user.nickname }}</p>
            <p>手机号：{{ item.user.phone }}</p>
            <p>核销时间：{{ item.create_time }}</p>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import { List, Cell, PullRefresh } from 'vant'
import moment from 'moment'
export default {
  name: 'VerificationList',
  components: {
    [Cell.name]: Cell,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
  },
  created() {
    moment.locale('zh-cn')
  },
  data() {
    return {
      list: [],
      finished: false,
      loading: false,
      refreshing: false,
      queryParams: {
        page: 1,
        limit: 10,
      },
    }
  },
  methods: {
    fomattTime(time) {
      return moment(time * 1000).format('LL')
    },
    onRefresh() {
      this.queryParams.page = 1
      this.refreshing = true
      this.queryList()
    },
    queryList() {
      this.loading = true
      this.finished = false
      this.$http({
        url: '/merchant/userUseCouponIndex',
        method: 'get',
        data: this.$http.adornParams(this.queryParams, false),
      })
        .then(({ data }) => {
          this.list = data.data
          this.loading = false
          if (data.last_page > this.queryParams.page) {
            this.page++
          } else {
            this.refreshing = false
            this.finished = true
          }
        })
        .catch(() => {
          this.finished = true
          this.loading = false
          this.refreshing = false
        })
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  height: 100vh;
  padding: 16px 32px;
  background: #f9f9f9;
  .item {
    height: 84px;
    background: #ffffff;
    border-radius: 16px 16px 16px 16px;
    display: flex;
    margin: 10px 0;
    .avatar {
      width: 50px;
      height: 50px;
      object-fit: contain;
      padding: 15px;
    }
    .info {
      height: 50px;
      overflow: hidden;
      padding: 10px 0;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      margin: 0;
      line-height: 20px;
    }
  }
}
</style>
